// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-confirm-email-js": () => import("./../src/pages/confirm-email.js" /* webpackChunkName: "component---src-pages-confirm-email-js" */),
  "component---src-pages-confirmation-js": () => import("./../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-contact-email-js": () => import("./../src/pages/contact/email.js" /* webpackChunkName: "component---src-pages-contact-email-js" */),
  "component---src-pages-contact-sales-js": () => import("./../src/pages/contact/sales.js" /* webpackChunkName: "component---src-pages-contact-sales-js" */),
  "component---src-pages-cookie-js": () => import("./../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-desk-booking-js": () => import("./../src/pages/desk-booking.js" /* webpackChunkName: "component---src-pages-desk-booking-js" */),
  "component---src-pages-docs-index-js": () => import("./../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-docs-workplace-desk-booking-grid-js": () => import("./../src/pages/docs/workplace/desk-booking/grid.js" /* webpackChunkName: "component---src-pages-docs-workplace-desk-booking-grid-js" */),
  "component---src-pages-docs-workplace-desk-booking-index-js": () => import("./../src/pages/docs/workplace/desk-booking/index.js" /* webpackChunkName: "component---src-pages-docs-workplace-desk-booking-index-js" */),
  "component---src-pages-docs-workplace-index-js": () => import("./../src/pages/docs/workplace/index.js" /* webpackChunkName: "component---src-pages-docs-workplace-index-js" */),
  "component---src-pages-docs-workplace-manual-index-js": () => import("./../src/pages/docs/workplace/manual/index.js" /* webpackChunkName: "component---src-pages-docs-workplace-manual-index-js" */),
  "component---src-pages-docs-workplace-manual-password-reset-js": () => import("./../src/pages/docs/workplace/manual/password-reset.js" /* webpackChunkName: "component---src-pages-docs-workplace-manual-password-reset-js" */),
  "component---src-pages-docs-workplace-manual-profile-js": () => import("./../src/pages/docs/workplace/manual/profile.js" /* webpackChunkName: "component---src-pages-docs-workplace-manual-profile-js" */),
  "component---src-pages-docs-workplace-manual-today-page-js": () => import("./../src/pages/docs/workplace/manual/today-page.js" /* webpackChunkName: "component---src-pages-docs-workplace-manual-today-page-js" */),
  "component---src-pages-docs-workplace-occupancy-index-js": () => import("./../src/pages/docs/workplace/occupancy/index.js" /* webpackChunkName: "component---src-pages-docs-workplace-occupancy-index-js" */),
  "component---src-pages-docs-workplace-occupancy-team-assign-js": () => import("./../src/pages/docs/workplace/occupancy/team-assign.js" /* webpackChunkName: "component---src-pages-docs-workplace-occupancy-team-assign-js" */),
  "component---src-pages-feature-js": () => import("./../src/pages/feature.js" /* webpackChunkName: "component---src-pages-feature-js" */),
  "component---src-pages-hybrid-work-js": () => import("./../src/pages/hybrid-work.js" /* webpackChunkName: "component---src-pages-hybrid-work-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-compliance-js": () => import("./../src/pages/legal/compliance.js" /* webpackChunkName: "component---src-pages-legal-compliance-js" */),
  "component---src-pages-legal-cookie-policy-js": () => import("./../src/pages/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-js" */),
  "component---src-pages-legal-data-protection-js": () => import("./../src/pages/legal/data-protection.js" /* webpackChunkName: "component---src-pages-legal-data-protection-js" */),
  "component---src-pages-legal-imprint-js": () => import("./../src/pages/legal/imprint.js" /* webpackChunkName: "component---src-pages-legal-imprint-js" */),
  "component---src-pages-legal-license-terms-js": () => import("./../src/pages/legal/license-terms.js" /* webpackChunkName: "component---src-pages-legal-license-terms-js" */),
  "component---src-pages-legal-requirements-js": () => import("./../src/pages/legal/requirements.js" /* webpackChunkName: "component---src-pages-legal-requirements-js" */),
  "component---src-pages-legal-subscription-conditions-js": () => import("./../src/pages/legal/subscription-conditions.js" /* webpackChunkName: "component---src-pages-legal-subscription-conditions-js" */),
  "component---src-pages-legal-terms-and-conditions-js": () => import("./../src/pages/legal/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-occupancy-management-js": () => import("./../src/pages/occupancy-management.js" /* webpackChunkName: "component---src-pages-occupancy-management-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-js": () => import("./../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-register-js": () => import("./../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-room-booking-js": () => import("./../src/pages/room-booking.js" /* webpackChunkName: "component---src-pages-room-booking-js" */),
  "component---src-pages-sitemap-js": () => import("./../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-space-management-js": () => import("./../src/pages/space-management.js" /* webpackChunkName: "component---src-pages-space-management-js" */),
  "component---src-pages-space-utilization-js": () => import("./../src/pages/space-utilization.js" /* webpackChunkName: "component---src-pages-space-utilization-js" */),
  "component---src-pages-support-js": () => import("./../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-sustainability-js": () => import("./../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-thankyou-js": () => import("./../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-work-experience-js": () => import("./../src/pages/work-experience.js" /* webpackChunkName: "component---src-pages-work-experience-js" */),
  "component---src-pages-workplace-automation-js": () => import("./../src/pages/workplace-automation.js" /* webpackChunkName: "component---src-pages-workplace-automation-js" */),
  "component---src-pages-workplace-mobile-app-js": () => import("./../src/pages/workplace-mobile-app.js" /* webpackChunkName: "component---src-pages-workplace-mobile-app-js" */),
  "component---src-templates-blog-template-js": () => import("./../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-event-template-js": () => import("./../src/templates/eventTemplate.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-news-template-js": () => import("./../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-resource-center-js": () => import("./../src/templates/resource-center.js" /* webpackChunkName: "component---src-templates-resource-center-js" */)
}

